body {
  padding: 0;
  margin: 0;
}
.App {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #2a2730;
}

.logIn {
  width: 600px;
  height: 350px;
  border: 5px solid #0091ff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logIn input{
  margin: 10px;
  width: 200px;
  height: 40px;
  border: 2px solid #0091ff;
  background-color: transparent;
  padding-left: 10px;
  color: white;
  font-size: 15px;
}

.logIn input:focus{
  outline: none;
}

.logIn button{

  width: 200px;
  height: 50px;
  border: none;
  background-color: #0091ff;
  color: white;
  font-size: 16px;
  margin-top: 45px;
}

.logIn button:hover{
  cursor: pointer;
  background-color: #037edc;
}

.chatContainer {
  width: 600px;
  height: 350px;
  border: 5px solid #0091ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chatContainer .messages {
  flex: 80%;
  width: 100%;
  padding-left: 20px;
}

.chatContainer .messageInputs {
  flex: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;

}

.chatContainer .messageInputs input{
  flex: 80%;
  height: calc(100% -5px);
  border: none;
  border-top: 5px solid #0091ff;
  padding-left: 20px;
  font-size: 20px;
}

.chatContainer .messageInputs button{
  flex: 20%;
  height: 100%;
  background-color: #0091ff;
  border: none;
  color: white;
  font-size: 18px;
}

.messageContainer {
  display: flex;
  width: calc(100% - 30px);
}

.messageContainer h1{
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 17px;
}

#You{
  justify-content: flex-start;
}

#Other {
  justify-content: flex-end;
}

#Other .messageIndividual {
  background-color: #5ff064;
  color: black;
}
.messageIndividual {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  background-color: #0091ff;
  opacity: 0.9;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 10px;
  margin-top: 20px;

}